$theme1: #102694; // Sport-ID
$theme2: #009FE3; // Zwemmen
$theme3: #E30613; // Sport
$theme4: #3AAA35; // Ondersteuning
$theme5: #EF7D00; // Oranje
$theme6: #951B81; // Purple
$theme7: #AFCA0B; // Light-green

$primary: $theme1 !default;
$secondary: $theme4 !default;
$dark: #1D1D1D;
$light: #F2F3F5;

$theme-colors: (
        'theme1': $theme1,
        'theme2': $theme2,
        'theme3': $theme3,
        'theme4': $theme4,
        'theme5': $theme5,
        'theme6': $theme6,
        'theme7': $theme7
);
$themes: (
        1: $theme1,
        2: $theme2,
        3: $theme3,
        4: $theme4,
        5: $theme5,
        6: $theme6,
        7: $theme7
);

$border-radius: 0px;
$border-radius-lg: 0px;
$btn-border-radius: 8px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$input-border-radius: 8px;
$custom-file-border-radius: 8px;
$custom-select-border-radius: 8px;

$hamburger-layer-color: $dark !default;
$hamburger-active-layer-color: $dark !default;

$font-size-base: 0.9rem;
$line-height-base: 1.8;

@import url("https://use.typekit.net/xye5fod.css");

$font-family-base: "open-sans", sans-serif;

h1, h2,
.h1, .h2{
  font-family: "nunito", sans-serif;
  font-weight: 800 !important;
  color: $primary;
}
h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
  font-family: "nunito", sans-serif;
  font-weight: 700 !important;
  color: $primary;
}

@import '@/src/scss/main.scss';

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss'; // Mijn omgeving css (sidebar theme)

section {
  @extend .position-relative;
}

.body, .footer{
  overflow-x: hidden;
}

.social--icons {
  @extend .mt-2;
}

.accordion button:after {
  color: $theme1 !important;
}


.login--button {
  @extend .btn-primary;
  @extend .border-0;
  @extend .text-white;
}

.breadcrumb--bar {
  @extend .bg-light;
}

.semi-bold, .watermark {
  font-weight: 600 !important;
}

.account-button--style, .account-dropdown--style {
  @extend .my-2;
  @extend .ml-3;
}

.pagination {
  @extend .mb-5;
  .page-link {
    background-color: $light;
    border-color: $theme1;
    font-weight: 600;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: $btn-border-radius;
    border-bottom-left-radius: $btn-border-radius;
  }
  .page-item:last-child .page-link {
    border-top-right-radius: $btn-border-radius;
    border-bottom-right-radius: $btn-border-radius;
  }
}

#Rooster, .rooster {
  td {
    a:not(.btn) {
      text-decoration: underline;
    }
  }
}

#main-content, main {
  min-height: 55vh;
  @extend .pt-5;
  .has-user-content {
    table {
      @extend .table;
      @extend .border-0;

      &:not(.planner-table) {
        width: 100% !important;
      }

      tr {
        background-color: inherit;
        &:hover {
          background-color: inherit;
        }
      }

      td, th {
        border-left: 0 !important;
        border-right: 0 !important;
        border-top: 1px solid #d3d3d3;
        border-bottom: 0 !important;
      }

      thead {
        td, th {
          border-top: 0 !important;
          font-weight: 600;
          background-color: $light;
        }
      }
    }
  }
}

.home-cta-banner {
  animation: easeIn 1.5s linear;
  .header-animate {
    animation: mymove 1s linear;
  }
}

@keyframes mymove {
  0% {
    opacity: 0;
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes easeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.header--intrest {
  @extend .position-absolute;
  @extend .bottom-0;
  @extend .p-3;
  @extend .w-100;
}

.navbar-brand--img {
  width: 250px;
  position: absolute;
  @extend .ml-2;
  @extend .my-2;
  @include media-breakpoint-down(xs) {
    width: 180px;
    margin-top: 20px !important;
  }
}

.news--container, .agenda--container {
  padding-top: 0 !important;
  font-size: 0.9em;
}

.navbar-collapse {
  .navbar-nav {
    .nav-link, .dropdown-item {
      @extend .position-relative;
      font-size: 0.9em;
      @include media-breakpoint-down(md) {
        border-top: solid 1px lightgrey;
        text-align: left;
        padding-left: 1em;
        font-size: 1em;
      }
    }
    .nav-item {
      @extend .position-relative;
      font-size: 0.9em;
    }
  }
}


.nav-item > .dropdown-menu {
  @include media-breakpoint-down(md) {
    text-align: left;
  }
}

.sub-dropdown {
  @include media-breakpoint-down(md) {
    margin-top: 0;
    padding-top: 0;
    a {
      padding-left: 2em !important;
    }
  }
}

.top-menu {
  @include media-breakpoint-down(lg) {
    margin-bottom: -10px;
  }
}

.navbar-nav > .nav-item {
  font-weight: 600 !important;
  margin-right: 0.7rem;
}

.navbar-nav > .nav-item:not(:last-child):after {
  @include media-breakpoint-up(lg) {
    transform: skew(-18deg);
    z-index: 1;
    pointer-events: none;
    position: absolute;
    width: 2px;
    height: 30px;
    top: 4px;
    background: $theme2;
    right: -7px;
    display: block;
    content: '';
  }
}

.part-category-nav {
  .h3 {
    @extend .mb-3;
  }
}

.ticket-list-item--inner, .list-group-item-action {
  border: 0;
  background-color: $light;
  border-bottom: solid 1px white;
  .card-title {
    @extend h5;
    font-weight: 600 !important;
  }
  .card-subtitle {
    @extend .mt-2;
    color: $theme1 !important;
    font-weight: 600 !important;
  }
  .fas {
    @extend .d-none;
  }
  a:not(.btn-outline-primary) {
    color: $theme1 !important;
  }
}

.card-text, .topbar-card--link {
  font-size: 0.9em;
  .fas {
    font-size: 1em;
  }
}

.topbar-card--link, .shoppingcart--icon {
  .badge-pill {
    border-radius: 8px !important;
    top: -7px !important;
    @include media-breakpoint-down(lg) {
      right: -10px !important;
    }
  }
}

.card-stretch-hover {
  .card-img-top {
    width: 100%;
    transition: transform .3s;
    @extend .h-auto;
    @include media-breakpoint-down(md) {
      aspect-ratio: 1.7;
      object-fit: cover;
    }
  }
  .card-button-top {
    transition: all .3s;
  }
  &:hover {
    .card-img-top {
      transform: scale(1.1);
    }
    .card-button-top {
      padding-right: 22px !important;
    }
  }
}

.footer {
  a {
    text-decoration: underline;
    color: white;
  }
  h4 {
    color: white !important;
  }

  .footer--rico {
    width: 250px;
    height: 250px;
    margin-right: 10px;
  }

  @media (max-width: 991.98px) { /* Bootstrap md breakpoint */
    .footer--rico {
      /* Remove position: absolute */
      /* Adjust width and margins as needed */
      position: relative; /* Ensure the image stays in flow */
      width: 200px;
      height: auto; /* Maintain aspect ratio */
      margin-top: 20px; /* Adjust as needed */
      margin-right: 0;
    }
  }
}

.btn {
  text-transform: uppercase;
}

.btn-light {
  @extend .text-theme1;
}

.nav-pills {
  .nav-link {
    border-radius: $btn-border-radius;
    text-transform: uppercase;
  }
}

.mobile-hamburger-color {
  font-size: 1rem;
}

.welcome {
  @extend .text-white;
}

.footer--socials {
  a {
    background-color: white;
    border-radius: 50%;
    padding: 2px 5px;
    color: #176FBB;
    @extend .mr-1;
  }
  a:hover {
    color: $theme1;
  }
}

.left--bar {
  font-weight: 600;
  font-size: 0.9em;
  margin-right: 18px !important;
  &:after {
    transform: skew(-18deg);
    z-index: 1;
    pointer-events: none;
    position: absolute;
    width: 2px;
    height: 30px;
    top: -2px;
    background: $theme2;
    right: -14px;
    display: block;
    content: '';
  }
}

.subhome--logo {
  max-height: 140px;
  max-width: 340px !important;
}

.dropdown--logo {
  max-height: 65px;
  @include media-breakpoint-down(md) {
    //border-top: solid 1px lightgrey;
  }
}

.dropdown-menu {
  border: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
  min-width: 14rem;
  @include media-breakpoint-down(md) {
    box-shadow: none;
    //border-top: solid 1px lightgrey;
  }
}

.hamburger {
  @extend .pt-2;
}

.btn {
  font-weight: 600 !important;
}

.dropdown-submenu > a{
  font-weight: 400;
  color: $dark;
}

.text-shadow {
  text-shadow: 2px 2px 4px #00000090;
}

.img-responsive--bg {
  width: 100%;
  z-index: -1;
  @include media-breakpoint-up(lg) {
    width: 63%;
  }
  img {
    object-fit: cover;
  }
}


@mixin faded {
  @include media-breakpoint-up(lg) {
    &.faded-left:after {
      box-shadow: inset 90px 0 65px (-30px);
    }
  }
}
.faded {
  position: relative;
  display: inline-block;
  color: white;

  &:after {
    @include media-breakpoint-up(lg) {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-collapse: separate;
    }
  }

  @include faded;
}

//gradients theme1

// dark left side
.bg-gradient {
  background: linear-gradient(270deg, rgba(0,159,227,0.85) 0%, rgba(16,38,148,0.85) 100%)!important;
}
// dark right side
.bg-gradient--flip {
  background: linear-gradient(90deg, rgba(0,159,227,0.85) 0%, rgba(16,38,148,0.85) 100%)!important;
}
// dark up side
.bg-gradient--up1 {
  background: linear-gradient(0deg, rgba(0,159,227,0.85) 0%, rgba(16,38,148,0.85) 100%)!important;
}
// dark down side
.bg-gradient--down {
  background: linear-gradient(180deg, rgba(0,159,227,0.85) 0%, rgba(16,38,148,0.85) 100%)!important;
}

//gradients theme2

// dark up side
.bg-gradient--up2 {
  background: linear-gradient(0deg, rgba(37, 197, 225, 0.91) 0%, rgba(20, 118, 227, 0.85) 100%)!important;
}

//gradients theme3

.bg-gradient--up3 {
  background: linear-gradient(0deg, rgba(245, 67, 36, 0.89) 0%, rgba(183, 2, 2, 0.85) 100%)!important;
}

//gradients theme4

.bg-gradient--up4 {
  background: linear-gradient(0deg, rgba(113, 239, 106, 0.89) 0%, rgba(58, 170, 53, 0.85) 100%)!important;
}

//gradients theme5

.bg-gradient--up5 {
  background: linear-gradient(0deg, rgba(255, 167, 74, 0.85) 0%, rgba(239, 125, 0, 0.85) 100%)!important;
}

//gradients theme6

.bg-gradient--up6 {
  background: linear-gradient(0deg, rgba(225, 82, 202, 0.89) 0%, rgba(149, 27, 129, 0.85) 100%)!important;
}

//gradients theme7

.bg-gradient--up7 {
  background: linear-gradient(0deg, rgba(225, 248, 89, 0.85) 0%, rgba(175, 202, 11, 0.85) 100%)!important;
}

.mobile--height {
  @include media-breakpoint-down(md) {
    height: 380px;
  }
  @include media-breakpoint-down(sm) {
    height: 240px;
  }
}

.home-cta-banner {
  overflow-x: hidden;
  height: 570px;
  @extend .d-flex;
  background-size: cover;
  background-position: top;
  margin-top: -45px;
  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 3.8rem;
    }
  }
  .overlay--banner:before {
    content: '';
    position: absolute;
    background: rgb(0,159,227);
    @extend .bg-gradient--down;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 130px 100%);
  }
  .overlay--banner-bg {
    background-color: rgba(0, 0, 0, 0.2);
  }
  @include media-breakpoint-down(md) {
    height: 320px !important;
  }
}

.content--banner {
  max-height: 500px;
  background-position: center;
}

.cta-banner-button {
  @extend .text-white;
  @extend .p-2;
  @extend .pr-3;
  max-width: 100%;
  min-width: 50%;
  font-weight: 600 !important;
  font-size: 0.9em;
  width: fit-content !important;
  padding-left: 2.5em !important;
  &:before {
    clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
    content: '';
    background-color: black;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 85%;
    bottom: 0;
    right: 0;
  }
}

.alert-banner {
  content: '';
  background-color: white;
  height: auto;;
  opacity: 85%;
  bottom: 10px;
  @include media-breakpoint-up(lg) {
    clip-path: polygon(30px 0%, 100% 0%, 100% 100%, 0% 100%);
    right: 0;
    width: 35%;
    max-width: 520px;
    padding: 1em 1em 1em 3em;
    p {
      margin: 0 !important;
    }
  }
}

.transform-banner-base {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
}

.hero-video--thumb {
  @extend .w-100;
}

.line-skew-base {
  @include media-breakpoint-up(md) {
    transform: skew(-18deg);

    z-index: -1;
    pointer-events: none;
    width: 2px;
    left: 70%;
  }
}

.line-skew-base--flipped {
  @include media-breakpoint-up(md) {
    transform: skew(-18deg);

    z-index: -1;
    pointer-events: none;
    width: 2px;
    left: 30%;
  }
}

@each $i, $key in $themes {
  .bg-trans-theme#{$i} {
    background-color: rgba(red($key), green($key), blue($key), 0.7);
  }
  .cta-theme#{$i}:before {
    background-color: $key;
  }
  .line-theme#{$i}-skew-75:after {
    @extend .transform-banner-base;
    @extend .line-skew-base;
    background: $key;
  }
  .line-theme#{$i}-skew-25:after {
    @extend .transform-banner-base;
    @extend .line-skew-base--flipped;
    background: $key;
  }
  .h1-theme#{$i} {
    h1 {
      color: $key !important;
    }
  }
}

.left-white-skew:before {
  @extend .transform-banner-base;
  top: -5px;
  bottom: -5px;
  background: #fff;
  z-index: 10;
  left: -100px;
  right: calc( 50% + (1264px / 2) + 100px );
  transform: skew(-18deg);

}

.container--menu {
  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.fusion-header:after {
  @include media-breakpoint-down(md) {
    bottom: -20px;
    left: calc(0px - (150vw - 608px) / 2);
  }
  @include media-breakpoint-down(sm) {
    bottom: -15px;
    left: calc(0px - (230vw - 608px) / 2);
  }
  content: '';
  display: block;
  position: absolute;
  top: 99%;
  right: -40px;
  left: calc(0px - (100vw - 608px) / 2);
  bottom: -30px;
  background: #fff;
  transform: skew(-18deg);

  width: 80.7%;
  z-index: 999;
}
.header--gray {
  z-index: -2;
  height: 45px;
  @extend .bg-light;
  @extend .position-relative;
}


//3 lines left
.white-line03:before {
  @extend .transform-banner-base;
  transform: skew(-18deg);

  z-index: 1;
  pointer-events: none;
  width: 3px;
  background: #fff;
  left: 7%;
  @include media-breakpoint-down(md) {
    left: 9%;
  }
}
.white-line02:before {
  @extend .transform-banner-base;
  transform: skew(-18deg);

  z-index: 1;
  pointer-events: none;
  width: 2px;
  background: #fff;
  left: 6%;
}
.white-line01:before {
  @extend .transform-banner-base;
  transform: skew(-18deg);

  z-index: 1;
  pointer-events: none;
  width: 1px;
  background: #fff;
  left: 5%;
  @include media-breakpoint-down(md) {
    left: 3%;
  }
}

//3 lines right
.white-line03-right:before {
  @extend .transform-banner-base;
  transform: skew(-18deg);

  z-index: 1;
  pointer-events: none;
  width: 3px;
  background: #fff;
  right: 5%;
  @include media-breakpoint-down(md) {
    right: 3%;
  }
}
.white-line02-right:before {
  @extend .transform-banner-base;
  transform: skew(-18deg);

  z-index: 1;
  pointer-events: none;
  width: 2px;
  background: #fff;
  right: 6%;
}
.white-line01-right:before {
  @extend .transform-banner-base;
  transform: skew(-18deg);

  z-index: 1;
  pointer-events: none;
  width: 1px;
  background: #fff;
  right: 7%;
  @include media-breakpoint-down(md) {
    right: 9%;
  }
}

// cta banner 04
.cta-banner04 {
  &:before {
    @extend .transform-banner-base;
    background-color: rgba(255, 255, 255, 0);
    background-position: center center;
    left: -100px;
    max-height: 550px;
    right: calc(55% + 100px);
    transform: skew(18deg);
    @extend .bg-gradient--flip;
    @include media-breakpoint-down(sm) {
      max-height: 220px;
      right: calc(32% + 100px);
    }
  }

  &:after {
    @extend .transform-banner-base;
    transform: skew(-18deg);
    z-index: 1;
    pointer-events: none;
    width: 2px;
    background: #fff;
    left: 17.5%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

//cta banner 02
.cta-banner02 {
  background-size: cover;
  background-position: center;
  &:after {
    @extend .transform-banner-base;
    transform: skew(18deg);
    z-index: 99;
    pointer-events: none;
    width: 2px;
    background: #fff;
    left: 50%;
    @include media-breakpoint-down(md) {
      left: 80%;
    }
  }

  &:before {
    @extend .transform-banner-base;
    background-color: rgba(255, 255, 255, 0);
    @extend .bg-gradient--up1;
    background-position: center center;
    right: -100px;
    left: calc(50%);
    transform: skew(-18deg);
    @include media-breakpoint-down(md) {
      left: 80%;
    }
  }
}
.cta-banner02-theme1 {
  @extend .cta-banner02;
}
.cta-banner02-theme2 {
  @extend .cta-banner02;
  &:before {
    @extend .bg-gradient--up2;
  }
}
.cta-banner02-theme3 {
  @extend .cta-banner02;
  &:before {
    @extend .bg-gradient--up3;
  }
}
.cta-banner02-theme4 {
  @extend .cta-banner02;
  &:before {
    @extend .bg-gradient--up4;
  }
}
.cta-banner02-theme5 {
  @extend .cta-banner02;
  &:before {
    @extend .bg-gradient--up5;
  }
}
.cta-banner02-theme6 {
  @extend .cta-banner02;
  &:before {
    @extend .bg-gradient--up6;
  }
}
.cta-banner02-theme7 {
  @extend .cta-banner02;
  &:before {
    @extend .bg-gradient--up7;
  }
}

//cta banner 03
.cta-banner03 {
  &:after {
    @extend .transform-banner-base;
    transform: skew(18deg);
    z-index: 99;
    pointer-events: none;
    width: 2px;
    background: #fff;
    left: 50%;
    @include media-breakpoint-down(md) {
      left: 20%;
    }
  }

  &:before {
    @extend .transform-banner-base;
    background-color: rgba(255, 255, 255, 0);
    @extend .bg-gradient--up1;
    background-position: center center;
    left: -100px;
    right: calc(50%);
    transform: skew(-18deg);
    @include media-breakpoint-down(md) {
      left: 80%;
    }
  }
}

.intrest-badge {
  height: 1.8rem;
  white-space: pre-wrap;
  right: 1rem;
  top: -1.1rem;

  p {
    vertical-align: middle;
    font-size: 0.6rem;
  }
}